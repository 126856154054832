<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Pump Location" />
      <b-row class="my-4">
        <b-colxx md="6" sm="12">
          <b-table 
            id="depot-list-table"
            responsive
            head-variant="dark"
            :items="depotLocationList"
            :fields="depot_location_list_fields"
          >
            <template #head(action)>
              <i v-if="permissions.includes('CanEditDeviceLocation')" class="fas fa-square-plus text-primary text-white icon-pointer mr-2" @click="createNewDepot()" />
            </template>
            <template #cell(action)="row">
              <i
                v-if="permissions.includes('CanEditDeviceLocation')"
                class="fas fa-pencil text-default icon-pointer"
                @click="goDepotDetail(row.item, row.index)"
              />
              <i v-if="!row.item.is_active_yn" class="fas fa-do-not-enter text-danger ml-2" />
              <i class="fas fa-pump text-info icon-pointer ml-2" @click="getPumpList(row.item)" />
            </template>
          </b-table>
        </b-colxx>
        <b-colxx md="6" sm="12">
        <m-spinny-message v-if="gettingDepotDetail" message="Please wait while we retrieve the depot details." />
        <div v-if="!gettingDepotDetail">
          <b-table 
            id="depot-list-table"
            responsive
            head-variant="dark"
            :items="depotPumpList"
            :fields="depot_pump_list_fields"
          >
            <template #cell(action)="row">
              <i
                class="fas fa-eye text-default icon-pointer"
                @click="goPumpDetail(row.item, row.index)" />
            </template>
            <template #cell(is_leased_yn)="row">
              <i
                class="fas"
                :class="row.item.is_leased_yn ? 'fa-circle-check text-success' : 'fa-circle-xmark'" />
            </template>
          </b-table>
        </div>
        </b-colxx>
      </b-row>
    </div>

    <!--  -->
    <!-- Depot Detail Modal -->
    <!--  -->
    <b-modal
      id="depot-detail-modal"
      v-model="depotDetailModal"
      :title="depotDetailModalTitle"
      size="md"
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-bg-variant="default"
      header-text-variant="white"
    >
      <b-row class="my-1">
        <b-alert v-model="showEditDepotAlert" :variant="editDepotAlertVariant" class="w-100" dismissible>{{ editDepotAlertMessage }}</b-alert>
      </b-row>
      <div>
        <b-form-row>
          <b-colxx md="12" sm="12">
            <m-text-input
              id="depot-name"
              label="Depot Name"
              :rules="{ required: true, max: 200 }"
              :value="editDepot.name"
              :alternative="false"
              :group="false"
              name="Depot Name"
              @update-value="
                (input) => {
                  editDepot.name = input;
                }
              "
            />
          </b-colxx>
          <b-colxx md="12" sm="12"
            ><b-form-group
              id="depot-inactive-fieldset"
              label="Is Active"
              label-for="depot-inactive-yn"
              class="field-label-form-group mt-2"
            >
              <b-form-checkbox
                id="dept-inactive-yn"
                v-model="editDepot.is_active_yn"
              ></b-form-checkbox> </b-form-group
          ></b-colxx>
        </b-form-row>
      </div>

      <template #modal-footer="{}">
        <b-button
          v-if="!showEditDepotAlert"
          size="md"
          class="mr-2"
          variant="success"
          :disabled="depotIsSaving"
          @click="saveDepotDetail()"
        >
          Save
        </b-button>
        <b-button size="md" class="mr-2" variant="info" :disabled="depotIsSaving" @click="closeDepotDetailModal()">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import MSpinnyMessage from "@/components/loader/MSpinnyMessage.vue"

  const newDepotObj = {
    depot_id: null,
    name: "",
    is_active_yn: true,
  }

  export default {
    name: "PumpDepotHome",
    components: {
      MSpinnyMessage,
    },

    data() {
      return {
        searchKeyword: "",

        depotLocationList: [],
        depot_location_list_fields: [
          {
            key: "action",
            label: ""
          },
          {
            key: "name",
            label: "Location"
          },
          {
            key: "leased_pump_count",
            label: "Leased"
          },
          {
            key: "owned_pump_count",
            label: "Owned"
          },
        ],

        depotPumpList: [],
        depot_pump_list_fields: [
          {
            key: "action",
            label: ""
          },
          {
            key: "serial_no",
            label: "Serial Number"
          },
          {
            key: "pump_type_name",
            label: "Type"
          },
          {
            key: "is_leased_yn",
            label: "Leased"
          },
          {
            key: "purchase_date",
            label: "Purchase Date"
          },
        ],

        editDepot: {},
        editDepotYN: null,
        editDepotIndex: null,

        gettingDepotDetail: false,

        depotDetailModal: false,
        depotIsSaving: false,

        showEditDepotAlert: false,
        editDepotAlertVariant: "",
        editDepotAlertMessage: "",

        current_page: 1,
        per_page: 10,
        total_rows: null,
        prev_page_link: "",
        next_page_link: "",
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      pageTotal() {
        var page_total = 1;
        page_total = Math.ceil(this.total_rows / this.per_page);
        return page_total;
      },

      depotDetailModalTitle() {
        if (!this.editDepotYN) {
          return "Create New Depot"
        }
        if (this.editDepot) {
          return "Edit Depot"
        }
        return ""
      },
    },

    created() {},

    async mounted() {
      this.$store.dispatch("common/showLoader", true);
      document.title = "Device Locations";

      await django_session.get("erp/pump/depot/list/", {
        params: {
          page_size: 0
        }
      }).then((response) => {
        this.depotLocationList = response.data
        this.$store.dispatch("common/showLoader", false);
      }).catch((error) => {
        this.$store.dispatch("common/showLoader", false);
      })
      
    },

    methods: {
      createNewDepot() {
        this.editDepot = JSON.parse(JSON.stringify(newDepotObj))
        this.editDepotYN = false
        this.editDepotIndex = null
        this.depotDetailModal = true
      },

      async saveDepotDetail() {
        this.depotIsSaving = true
        if (this.editDepotYN === false) {
          await django_session.post("erp/pump/depot/create/", {
            name: this.editDepot.name,
            is_active_yn: this.editDepot.is_active_yn,
            created_by: this.user.user_id,
            modified_by: this.user.user_id
          }).then((response) => {
            this.editDepotAlertMessage = "Depot created"
            this.editDepotAlertVariant = "success"
            this.showEditDepotAlert = true
            this.depotLocationList.push(response.data)
          }).catch((error) => {
            this.editDepotAlertMessage = "Error saving new depot"
            this.editDepotAlertVariant = "warning"
            this.showEditDepotAlert = true
          })
        }

        if (this.editDepotYN === true) {
          django_session.patch("erp/pump/depot/" + this.editDepot.depot_id + "/", {
            name: this.editDepot.name,
            is_active_yn: this.editDepot.is_active_yn,
            modified_by: this.user.user_id
          }).then((response) => {
            this.editDepotAlertMessage = "Depot updated"
            this.editDepotAlertVariant = "success"
            this.showEditDepotAlert = true
            this.depotLocationList[this.editDepotIndex].name = response.data.name
          }).catch((error) => {
            this.editDepotAlertMessage = "Error updating depot"
            this.editDepotAlertVariant = "warning"
            this.showEditDepotAlert = true
          })
        }
        this.depotIsSaving = false
      },

      async goDepotDetail(item, index) {
        // console.log(item, index)
        // this.gettingDepotDetail = true
        this.editDepot = JSON.parse(JSON.stringify(item))
        this.editDepotYN = true
        this.editDepotIndex = index
        this.depotDetailModal = true

        await django_session.get("erp/pump/depot/" + item.depot_id + "/").then((response) => {
          console.log(response.data)
        }).catch((error) => {
          console.log(error.response)
        })
        // this.gettingDepotDetail = false
      },

      async getPumpList(item) {
        this.gettingDepotDetail = true
        await django_session.get("erp/pump/depot/" + item.depot_id + "/").then((response) => {
          this.depotPumpList = response.data.pump_list
        }).catch((error) => {
          console.log(error.response)
        })
        this.gettingDepotDetail = false
      },

      closeDepotDetailModal() {
        this.depotDetailModal = false
        this.editDepot = JSON.parse(JSON.stringify(newDepotObj))
        this.editDepotYN = null
        this.editDepotIndex = null
        this.editDepotAlertMessage = ""
        this.editDepotAlertVariant = ""
        this.showEditDepotAlert = false
      },

      goPumpDetail(item) {
        this.$store.dispatch("pump/setPumpId", item.pump_id)
        this.$store.dispatch("pump/setPumpObj", item)
        this.$store.dispatch("pump/setEditPumpFromDepotFlat", true)
        this.$router.push({ name: 'PumpHome' })
      },
    },
  }
</script>
