<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Pump Home" />
      <form @submit.prevent="searchPump()">
        <b-row class="my-4 align-items-end">
          <b-colxx
            md="6"
            sm="12">
            <m-text-input
              id="search-pump-keyword"
              label="Search for Pump"
              :value="searchPumpKeyword"
              :alternative="false"
              :group="false"
              placeholder="Search by serial number"
              name="Search for Pump"
              @update-value="
                (input) => {
                  searchPumpKeyword = input
                }
              " />
          </b-colxx>
          <b-colxx
            md="6"
            sm="12">
            <b-button
              size="md"
              variant="info"
              class="mr-2"
              @click="searchPump()"
              >Search</b-button
            >
            <b-button
              size="md"
              variant="warning"
              class="mr-2"
              @click="clearPumpSearch()"
              >Clear</b-button
            >
            <b-button
              v-if="permissions.includes('CanCreateDevice')"
              size="md"
              variant="primary"
              @click="createNewPump()"
              >Create Pump</b-button
            >
          </b-colxx>
        </b-row>
      </form>

      <b-row class="my-4">
        <b-colxx
          md="6"
          sm="12">
          <m-spinny-message
            v-if="gettingPumpList"
            message="Please wait while we get the list." />
          <div v-if="!gettingPumpList">
            <b-table
              id="pump-list-table"
              responsive
              head-variant="dark"
              :items="pumpList"
              :fields="pump_list_fields">
              <template #cell(action)="row">
                <i
                  class="fas fa-eye icon-pointer"
                  :class="row.item.active_yn ? 'text-info' : 'text-danger'"
                  @click="goPumpDetail(row.item)" />
              </template>
              <template #cell(is_leased_yn)="row">
                <i
                  class="fas"
                  :class="row.item.is_leased_yn ? 'fa-circle-check text-success' : 'fa-circle-xmark'" />
              </template>
            </b-table>
            <b-pagination
              v-model="current_page"
              :total-rows="totalPumps"
              :per-page="per_page"
              :number-of-pages="pageTotal"
              size="sm"
              class="my-0"
              aria-controls="pump-list-table"
              @input="getPumpList(current_page)"></b-pagination>
          </div>
        </b-colxx>
        <b-colxx
          md="6"
          sm="12">
          <b-alert
            v-model="showEditPumpAlert"
            :variant="editPumpAlertVariant"
            class="w-100"
            dismissible
            >{{ editPumpAlertMessage }}</b-alert
          >
          <div v-if="!showActiveInactivePump">
            <b-card
              header-bg-variant="warning"
              hide-footer>
              <template #header>
                <span class="text-white">Pump Details</span>
                <span
                  v-if="editPumpAvailable"
                  class="float-right">
                  <b-button
                    size="sm"
                    variant="info"
                    @click="addNoteToPump()"
                    >New Note</b-button
                  >
                  <b-button
                    v-if="permissions.includes('CanTransferDevice')"
                    size="sm"
                    variant="primary"
                    @click="openTransferPump('depot')"
                    >Transfer to Depot</b-button
                  >
                  <b-button
                    v-if="permissions.includes('CanTransferDevice')"
                    size="sm"
                    variant="primary"
                    @click="openTransferPump('customer')"
                    >Transfer to Customer</b-button
                  >
                </span>
              </template>
              <div v-if="isGettingPumpDetail">
                <m-spinny-message message="Please wait while we get the pump details." />
              </div>
              <div v-if="!isGettingPumpDetail && editPumpAvailable">
                <b-row class="my-2">
                  <b-colxx
                    md="6"
                    sm="12">
                    <m-text-input
                      id="serial-number"
                      label="Serial Number"
                      :value="editPump.serial_no"
                      :alternative="false"
                      :group="false"
                      :readonly="true"
                      name="Serial Number" />
                  </b-colxx>
                  <b-colxx
                    md="6"
                    sm="12">
                    <m-text-input
                      id="serial-number"
                      label="Pump Type"
                      :value="editPump.pump_type_name"
                      :alternative="false"
                      :group="false"
                      :readonly="true"
                      name="Pump Type" />
                  </b-colxx>
                </b-row>
                <b-row class="my-2">
                  <b-colxx
                    md="3"
                    sm="12">
                    <b-form-group
                      id="pump-active-checkbox"
                      label="Active?"
                      label-for="pump-active-yn"
                      class="field-label-form-group mt-2">
                      <b-form-checkbox
                        id="pump-active-yn"
                        :disabled="!permissions.includes('CanEditDevice') || !permissions.includes('CanCreateDevice')"
                        v-model="editPump.active_yn"
                        @change="updatePumpActive()"></b-form-checkbox>
                    </b-form-group>
                  </b-colxx>
                  <b-colxx
                    md="3"
                    sm="12">
                    <b-form-group
                      id="pump-leased-checkbox"
                      label="Leased?"
                      label-for="pump-leased-yn"
                      class="field-label-form-group mt-2">
                      <b-form-checkbox
                        id="pump-leased-yn"
                        :disabled="!permissions.includes('CanEditDevice') || !permissions.includes('CanCreateDevice')"
                        v-model="editPump.is_leased_yn"
                        @change="savePumpActive()"
                      ></b-form-checkbox>
                    </b-form-group>
                  </b-colxx>
                  <b-colxx
                    md="6"
                    sm="12">
                    <m-text-input
                      id="inactive-date"
                      label="Inactive Date"
                      :value="editPump.inactive_date"
                      :alternative="false"
                      :group="false"
                      :readonly="true"
                      name="Inactive Date" />
                  </b-colxx>
                </b-row>
                <b-row class="my-2">
                  <b-colxx
                    md="12"
                    sm="12">
                    <b-table
                      id="pump-movement-list-table"
                      responsive
                      head-variant="dark"
                      :items="editPump.pump_movement_list"
                      :fields="pump_movement_list_fields">
                    </b-table>
                  </b-colxx>
                </b-row>
                <b-row class="my-2">
                  <b-colxx
                    md="12"
                    sm="12">
                    <b-table
                      id="pump-notes-list-table"
                      responsive
                      head-variant="dark"
                      :items="editPump.notes"
                      :fields="pump_notes_list_fields">
                      <template #cell(created_at)="row">
                        {{ new Date(row.item.created_at).toLocaleString() }}
                      </template>
                    </b-table>
                  </b-colxx>
                </b-row>
              </div>
            </b-card>
          </div>

          <div v-if="showActiveInactivePump">
            <!-- <div v-if="!editPump.active_yn"> -->
            <b-card
              header-bg-variant="danger"
              hide-footer>
              <template #header>
                <span class="text-white">{{ pumpInactiveCardTitle }}</span>
              </template>
              <b-row class="my-2">
                <b-colxx
                  md="12"
                  sm="12">
                  <b-card-text>
                    {{ pumpInactiveCardBody }}
                  </b-card-text>
                </b-colxx>
              </b-row>
              <m-spinny-message
                v-if="waitingForPumpActiveSave"
                message="Please wait while we update this pump." />
              <b-row
                v-if="!waitingForPumpActiveSave"
                class="my-2">
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-area
                    id="pump-inactive-reason"
                    :value="pumpActiveInactiveReasonText"
                    name="Notes"
                    :rows="5"
                    label="Reason"
                    :alternative="false"
                    :group="false"
                    @update-value="(input) => (pumpActiveInactiveReasonText = input)" />
                </b-colxx>
                <b-colxx
                  md="6"
                  sm="12">
                  <b-form-group
                    id="depot-select-active-inactive"
                    label="Select Depot to Move Pump"
                    label-for="depot"
                    class="field-label-form-group">
                    <b-form-select
                      id="active-inactive-depot-select"
                      v-model="pumpActiveInactiveDepot"
                      :options="depotLocationList"
                      required
                      value-field="depot_id"
                      text-field="name">
                    </b-form-select>
                  </b-form-group>
                </b-colxx>
              </b-row>
              <template #footer>
                <b-button
                  size="md"
                  class="mr-2"
                  variant="info"
                  :disabled="waitingForPumpActiveSave"
                  @click="cancelUpdatePumpActive()"
                  >Cancel
                </b-button>
                <b-button
                  size="md"
                  class="mr-2 float-right"
                  variant="danger"
                  :disabled="waitingForPumpActiveSave"
                  @click="savePumpActive()"
                  >{{ pumpInactiveButtonLabel }}
                </b-button>
              </template>
            </b-card>
            <!-- </div> -->
          </div>
        </b-colxx>
      </b-row>
    </div>

    <!--  -->
    <!-- Edit Pump Modal -->
    <!--  -->
    <b-modal
      id="depot-detail-modal"
      v-model="showPumpModal"
      :title="editPumpModalTitle"
      size="lg"
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-bg-variant="default"
      header-text-variant="white">
      <b-row class="my-1">
        <b-alert
          v-model="showEditPumpAlert"
          :variant="editPumpAlertVariant"
          class="w-100"
          dismissible
          >{{ editPumpAlertMessage }}</b-alert
        >
      </b-row>
      <div>
        <b-form-row>
          <b-colxx
            md="6"
            sm="12">
            <b-form-group
              id="pump-type-fieldset"
              label="Pump Type *"
              label-for="pump-type"
              class="field-label-form-group">
              <b-form-select
                id="pump-type"
                v-model="editPump.pump_type"
                :options="pumpTypes"
                required
                value-field="pump_type_id"
                text-field="name">
              </b-form-select>
            </b-form-group>
          </b-colxx>
          <b-colxx
            md="6"
            sm="12">
            <b-form-group
              id="depot-select-fieldset"
              label="Select Depot *"
              label-for="depot"
              class="field-label-form-group">
              <b-form-select
                id="depot"
                v-model="editPump.depot_id"
                :options="depotLocationList"
                required
                value-field="depot_id"
                text-field="name">
              </b-form-select>
            </b-form-group>
          </b-colxx>
          <b-colxx
            md="6"
            sm="12">
            <m-text-input
              id="serial-number"
              label="Serial Number"
              :rules="{ required: true, max: 100 }"
              :value="editPump.serial_no"
              :alternative="false"
              :group="false"
              name="Serial Number"
              @update-value="
                (input) => {
                  editPump.serial_no = input
                }
              " />
          </b-colxx>
          <b-colxx
            v-if="!editPump.is_leased_yn"
            md="6"
            sm="12">
            <m-text-input
              id="purchase-date"
              label="Purchase Date"
              type="date"
              :rules="{ required: !editPump.is_leased_yn }"
              :value="editPump.purchase_date"
              :alternative="false"
              :group="false"
              name="Purchase Date"
              @update-value="
                (input) => {
                  editPump.purchase_date = input
                }
              " />
          </b-colxx>
        </b-form-row>
        <b-form-row>
          <b-colxx
            md="4"
            sm="4">
            <b-form-group
              id="leased"
              label="Leased"
              label-for="is-leased"
              class="field-label-form-group mt-2">
              <b-form-checkbox
                id="is-leased"
                switch
                class="ml-5"
                v-model="editPump.is_leased_yn">
              </b-form-checkbox>
            </b-form-group>
          </b-colxx>
          <b-colxx
            md="4"
            sm="4">
            <b-form-group
              id="available"
              label="Available"
              label-for="is-available"
              class="field-label-form-group mt-2">
              <b-form-checkbox
                id="is-available"
                switch
                class="ml-5"
                v-model="editPump.is_available_yn">
              </b-form-checkbox>
            </b-form-group>
          </b-colxx>
          <b-colxx
            md="4"
            sm="4">
            <b-form-group
              id="deleted"
              label="Deleted"
              label-for="is-deleted"
              class="field-label-form-group mt-2">
              <b-form-checkbox
                id="is-deleted"
                switch
                class="ml-5"
                v-model="editPump.is_deleted_yn">
              </b-form-checkbox>
            </b-form-group>
          </b-colxx>
        </b-form-row>
      </div>

      <template #modal-footer="{}">
        <b-button
          v-if="!showEditPumpAlert"
          size="md"
          class="mr-2"
          variant="success"
          :disabled="pumpIsSaving || disableSavePumpButton"
          @click="savePump()">
          Save
        </b-button>
        <b-button
          size="md"
          class="mr-2"
          variant="info"
          :disabled="pumpIsSaving"
          @click="closeEditPump()">
          Close
        </b-button>
      </template>
    </b-modal>

    <!--  -->
    <!-- Transfer Pump Modal -->
    <!--  -->
    <b-modal
      id="transfer-pump-modal"
      v-model="showTransferPumpModal"
      title="Transfer Pump"
      size="md"
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      :hide-footer="showTransferConfirm"
      header-bg-variant="default"
      header-text-variant="white">
      <b-row class="my-1">
        <b-alert
          v-model="showTransferPumpAlert"
          :variant="transferPumpAlertVariant"
          class="w-100"
          dismissible
          >{{ transferPumpAlertMessage }}</b-alert
        >
      </b-row>
      <m-spinny-message
        v-if="pumpTransferIsSaving"
        message="Please wait while we transfer this pump." />
      <div v-if="!pumpTransferIsSaving">
        <div v-if="showTransferConfirm">
          <b-row class="mb-4">
            <b-colxx
              md="12"
              sm="12">
              You're Ready to Transfer?
            </b-colxx>
          </b-row>
          <b-row>
            <b-colxx
              md="12"
              sm="12">
              <b-button
                size="md"
                class="mr-2"
                variant="success"
                @click="saveTransferPump()">
                Confirm Transfer
              </b-button>
              <b-button
                size="md"
                class="mr-2"
                variant="info"
                @click="
                  () => {
                    showTransferConfirm = false
                  }
                ">
                Go Back
              </b-button>
            </b-colxx>
          </b-row>
        </div>
        <div v-if="!showTransferConfirm">
          <b-form-row>
            <b-colxx
              md="6"
              sm="12">
              <m-text-input
                id="serial-number"
                label="Serial Number"
                :value="editPump.serial_no"
                :alternative="false"
                :group="false"
                :readonly="true"
                name="Serial Number" />
            </b-colxx>
            <b-colxx
              md="6"
              sm="12">
              <m-text-input
                id="pump-type-name"
                label="Pump Type"
                :value="editPump.pump_type_name"
                :alternative="false"
                :group="false"
                :readonly="true"
                name="Pump Type" />
            </b-colxx>
            <b-colxx
              md="12"
              sm="12">
              <m-text-input
                id="pump-type-name"
                label="Current Location"
                :value="editPump.current_location"
                :alternative="false"
                :group="false"
                :readonly="true"
                name="Current Location" />
            </b-colxx>
          </b-form-row>
          <b-form-row>
            <b-colxx
              md="12"
              sm="12">
              <m-text-input
                id="transfer-date"
                label="Transfer Date"
                type="date"
                :value="transferDate"
                :alternative="false"
                :group="false"
                :rules="{ required: true }"
                name="Transfer Date"
                @update-value="
                  (input) => {
                    transferDate = input
                  }
                " />
            </b-colxx>
          </b-form-row>
          <b-form-row>
            <b-colxx
              md="12"
              sm="12">
              <m-text-area
                id="contact-note"
                :value="transferNotes"
                name="Notes"
                :rows="3"
                label="Transfer Notes"
                :alternative="false"
                :group="false"
                @update-value="(input) => (transferNotes = input)" />
            </b-colxx>
          </b-form-row>
          <b-form-row class="mt-2">
            <b-colxx
              v-if="transferType == 'depot'"
              md="12"
              sm="12">
              <b-form-group
                id="depot-select-fieldset"
                label="Select Depot"
                label-for="transfer-to-depot"
                class="field-label-form-group">
                <b-form-select
                  id="transfer-to-depot"
                  v-model="transferPumpToDepotId"
                  :options="filterActiveDepotList"
                  value-field="depot_id"
                  text-field="name">
                </b-form-select>
              </b-form-group>
            </b-colxx>

            <b-colxx
              v-if="transferType == 'customer'"
              md="12"
              sm="12">
              <form @submit.prevent="searchCustomer()">
                <m-spinny-message
                  v-if="isSearchingForCustomer"
                  message="Please wait while we search for customers." />
                <b-form-group
                  v-if="!isSearchingForCustomer"
                  id="customer-search-fieldset"
                  label="Search Customers"
                  label-for="search-customer"
                  class="field-label-form-group">
                  <b-input-group>
                    <b-form-input
                      id="transfer-to-customer"
                      v-model.trim="searchCustomerToTransfer">
                    </b-form-input>
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        variant="success"
                        @click="searchCustomer()"
                        >Search</b-button
                      >
                      <b-button
                        size="sm"
                        variant="info"
                        @click="clearSearchCustomer()"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </form>
              <b-row
                v-if="transferPumpToCustomer != null"
                class="mb-2">
                <b-colxx
                  md="12"
                  sm="12">
                  <m-text-input
                    id="transfer-pump-to-customer-name"
                    label="Transfer To"
                    :value="transferPumpToCustomer.customer_name"
                    :alternative="false"
                    :group="false"
                    :readonly="true"
                    name="Transfer To" />
                </b-colxx>
                <b-colxx
                  md="12"
                  sm="12">
                  <b-form-group
                    id="is-rental-depot-checkbox"
                    label="Customer is Rental Depot"
                    label-for="is-depot-yn"
                    class="field-label-form-group mt-2">
                    <b-form-checkbox
                      id="is-depot-yn"
                      v-model="customerIsRentalDepotYN"></b-form-checkbox>
                  </b-form-group>
                </b-colxx>
              </b-row>
            </b-colxx>
          </b-form-row>
          <div v-if="showCustomerSearchResults">
            <b-table
              id="pump-list-table"
              responsive
              head-variant="dark"
              :items="filteredCustomerSearchResults"
              :fields="transfer_to_customer_list_fields">
              <template #cell(select)="row">
                <i
                  class="fas icon-pointer"
                  :class="[
                    transferPumpToCustomer.erp_customer_id == row.item.erp_customer_id
                      ? 'fa-circle-check text-success'
                      : 'fa-circle-arrow-right',
                  ]"
                  @click="selectCustomerForTransfer(row.item)" />
              </template>
            </b-table>
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <b-button
          v-if="!pumpTransferSaveComplete"
          size="md"
          class="mr-2"
          variant="success"
          :disabled="pumpTransferIsSaving || disableSaveTransferButton"
          @click="confirmTransfer()">
          Save
        </b-button>
        <b-button
          size="md"
          class="mr-2"
          variant="info"
          :disabled="pumpTransferIsSaving"
          @click="closeTransferPump()">
          Close
        </b-button>
      </template>
    </b-modal>

    <!--  -->
    <!-- Add Note Modal -->
    <!--  -->
    <b-modal
      id="add-note-modal"
      v-model="showAddNoteModal"
      title="Add a Note"
      size="md"
      centered
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      header-bg-variant="default"
      header-text-variant="white">
      <b-row class="my-1">
        <b-alert
          v-model="showAddNoteAlert"
          :variant="addNoteAlertVariant"
          class="w-100"
          dismissible
          >{{ addNoteAlertMessage }}</b-alert
        >
      </b-row>
      <div>
        <b-form-row>
          <b-colxx
            md="12"
            sm="12">
            <m-text-area
              id="new-pump-note"
              :value="newPumpNoteInput"
              name="Notes"
              :rows="7"
              :rules="{ required: true, max: 4000 }"
              label="Notes"
              :alternative="false"
              :group="false"
              @update-value="(input) => (newPumpNoteInput = input)" />
          </b-colxx>
        </b-form-row>
      </div>

      <template #modal-footer="{}">
        <b-button
          v-if="!showAddNoteAlert"
          size="md"
          class="mr-2"
          variant="success"
          :disabled="pumpNoteIsSaving || noteFieldIsEmpty"
          @click="saveNewPumpNote()">
          Save
        </b-button>
        <b-button
          size="md"
          class="mr-2"
          variant="info"
          :disabled="pumpNoteIsSaving"
          @click="closeNewPumpNote()">
          Close
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import django_session from "@/api/django_session"
  import { mapGetters } from "vuex"

  import { newPumpObj } from "@/constants/objects/pump"

  export default {
    name: "PumpHome",
    components: {},

    data() {
      return {
        pumpTypes: [],
        depotLocationList: [],

        gettingPumpList: false,

        pumpList: [],
        pump_list_fields: [
          {
            key: "action",
            label: "",
          },
          {
            key: "serial_no",
            label: "Serial Number",
          },
          {
            key: "pump_type_name",
            label: "Type",
          },
          {
            key: "is_leased_yn",
            label: "Leased",
          },
          {
            key: "purchase_date",
            label: "Purchased",
          },
        ],
        totalPumps: null,
        next_page_link: "",
        prev_page_link: "",
        current_page: 1,
        per_page: 10,

        searchPumpKeyword: "",

        pumpIsSaving: false,

        showPumpModal: false,
        editPump: {},
        editPumpYN: null,
        editPumpIndex: null,

        pump_movement_list_fields: [
          {
            key: "transfer_date",
            label: "Transfer Date",
          },
          {
            key: "depot_name",
            label: "Depot Name",
          },
          {
            key: "customer_name",
            label: "Customer Name",
          },
        ],

        pump_notes_list_fields: [
          {
            key: "note",
            label: "Note",
          },
          {
            key: "created_at",
            label: "Date",
          },
          {
            key: "created_by_name",
            label: "Author",
          },
        ],

        transfer_to_customer_list_fields: [
          {
            key: "select",
            label: "",
          },
          {
            key: "customer_name",
            label: "Customer",
          },
          {
            key: "city",
            label: "City",
          },
        ],

        isGettingPumpDetail: false,

        showAddNoteModal: false,
        newPumpNoteInput: "",
        showAddNoteAlert: false,
        addNoteAlertMessage: "",
        addNoteAlertVariant: "",
        pumpNoteIsSaving: false,

        showEditPumpAlert: false,
        editPumpAlertVariant: "",
        editPumpAlertMessage: "",

        transferType: "",
        transferDate: null,
        transferNotes: "",
        showTransferPumpModal: false,
        showTransferPumpAlert: false,
        transferPumpAlertMessage: "",
        transferPumpAlertVariant: "",
        pumpTransferIsSaving: false,
        pumpTransferSaveComplete: false,

        transferPumpToDepotId: null,
        transferPumpToCustomer: {
          lid: null,
        },
        searchCustomerToTransfer: "",
        searchCustomerResults: [],
        isSearchingForCustomer: false,
        showCustomerSearchResults: false,
        customerIsRentalDepotYN: false,

        showTransferConfirm: false,

        showActiveInactivePump: false,
        pumpActiveInactiveReasonText: "",
        pumpActiveInactiveDepot: null,
        waitingForPumpActiveSave: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),
      ...mapGetters("pump", ["pumpId", "pumpObj", "editPumpFromDepotYN"]),

      editPumpModalTitle() {
        if (this.editPumpYN === true) {
          return "Edit Pump Detail"
        }
        if (this.editPumpYN === false) {
          return "Create New Pump"
        }
        return ""
      },

      noteFieldIsEmpty() {
        if (this.newPumpNoteInput === "") {
          return true
        }
        return false
      },

      disableSavePumpButton() {
        if (
          this.editPump.pump_type === null ||
          this.editPump.depot_id === null ||
          this.editPump.serial_no === ""
        ) {
          return true
        }
        if (this.editPump.is_leased_yn === false && this.editPump.purchase_date === null) {
          return true
        }
        return false
      },

      editPumpAvailable() {
        if (this.editPumpYN === true && (this.editPump.pump_id !== null || this.editPump.pump_id !== undefined)) {
          return true
        }
        return false
      },

      filteredCustomerSearchResults() {
        let list = this.searchCustomerResults
        let filtered_list = list.filter((x) => x.erp_customer_id !== null)
        return filtered_list
      },

      filterActiveDepotList() {
        let list = this.depotLocationList
        let filtered_list = list.filter((x) => x.is_active_yn === true)
        return filtered_list
      },

      disableSaveTransferButton() {
        if (this.transferType === "depot") {
          if (this.transferDate === null || this.transferPumpToDepotId === null) {
            return true
          }
          return false
        }
        if (this.transferType === "customer") {
          if (this.transferDate === null || this.transferPumpToCustomer.lid === null) {
            return true
          }
          return false
        }
        if (this.transferType === "") {
          return true
        }
        return false
      },

      pageTotal() {
        var page_total
        page_total = Math.ceil(this.totalPumps / this.per_page)
        return page_total
      },

      pumpInactiveCardTitle() {
        if (!this.editPump.active_yn) {
          return "Make Pump Inactive"
        }
        return "Make Pump Active"
      },
      pumpInactiveCardBody() {
        if (!this.editPump.active_yn) {
          return "Using the function will make this pump inactive and will no longer be available for use. To complete this action, please enter the reason why this pump is being made inactive. Once you save this action, the inactive date will be recorded as today."
        }
        return "Using the function will make this pump active and will be available for use."
      },
      pumpInactiveButtonLabel() {
        if (!this.editPump.active_yn) {
          return "Make Pump Inactive"
        }
        return "Make Pump Active"
      },
    },

    beforeRouteLeave(to, from, next) {
      this.$store.dispatch("pump/setPumpId", null)
      this.$store.dispatch("pump/setPumpObj", {})
      this.$store.dispatch("pump/setEditPumpFromDepotFlat", false)
      next()
    },

    created() {},

    async mounted() {
      this.$store.dispatch("common/showLoader", true)
      document.title = "Device Home"

      await django_session
        .get("erp/pump/depot/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.depotLocationList = response.data
        })
        .catch((error) => {})

      await django_session
        .get("erp/pump/type/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.pumpTypes = response.data
        })
        .catch((error) => {})

      if (!this.editPumpFromDepotYN) {
        await this.getPumpList()
      }

      if (this.editPumpFromDepotYN) {
        this.searchPumpKeyword = this.pumpObj.serial_no
        await this.searchPump()
        await this.goPumpDetail(this.pumpObj)
      }

      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async getPumpList() {
        this.gettingPumpList = true
        this.editPump = JSON.parse(JSON.stringify(newPumpObj))
        this.$store.dispatch("pump/setPumpId", null)
        this.editPumpYN = false
        this.editPumpIndex = null
        await django_session
          .get("erp/pump/list/", {
            params: {
              page: this.current_page,
            },
          })
          .then((response) => {
            this.pumpList = response.data.results
            this.totalPumps = response.data.count
            this.next_page_link = response.data.links.next
            this.prev_page_link = response.data.links.prev
          })
        this.gettingPumpList = false
      },

      createNewPump() {
        this.editPump = JSON.parse(JSON.stringify(newPumpObj))
        this.$store.dispatch("pump/setPumpId", null)
        this.$store.dispatch("pump/setPumpObj", {})
        this.$store.dispatch("pump/setEditPumpFromDepotFlat", false)
        this.editPumpYN = false
        this.editPumpIndex = null
        this.showPumpModal = true
      },

      async searchPump() {
        this.gettingPumpList = true
        this.current_page = 1
        await django_session
          .get("erp/pump/list/", {
            params: {
              page: 1,
              search_pump_keyword: this.searchPumpKeyword,
            },
          })
          .then((response) => {
            this.pumpList = response.data.results
            this.totalPumps = response.data.count
            this.next_page_link = response.data.links.next
            this.prev_page_link = response.data.links.prev
          })
          .catch((error) => {
            console.log(error.response)
          })
        this.gettingPumpList = false
      },

      clearPumpSearch() {
        this.current_page = 1
        this.searchPumpKeyword = ""
        this.editPumpYN = null
        this.editPumpIndex = null
        this.editPump = JSON.parse(JSON.stringify(newPumpObj))
        this.$store.dispatch("pump/setPumpId", null)
        this.$store.dispatch("pump/setPumpObj", {})
        this.$store.dispatch("pump/setEditPumpFromDepotFlat", false)
        this.getPumpList()
      },

      async savePump() {
        this.pumpIsSaving = true
        if (this.editPumpYN === false) {
          django_session
            .post("erp/pump/create/", {
              serial_no: this.editPump.serial_no,
              pump_type: this.editPump.pump_type,
              purchase_date: this.editPump.purchase_date,
              is_leased_yn: this.editPump.is_leased_yn,
              is_available_yn: this.editPump.is_available_yn,
              is_deleted_yn: this.editPump.is_deleted_yn,
              active_yn: this.editPump.active_yn,
              inactive_date: this.editPump.inactive_date,
              inactive_reason: this.editPump.inactive_reason,
              depot_id: this.editPump.depot_id,
            })
            .then((response) => {
              console.log(response.data)
              this.pumpList.push(response.data)
              this.editPumpAlertMessage = "New pump created."
              this.editPumpAlertVariant = "success"
              this.showEditPumpAlert = true
            })
            .catch((error) => {
              console.log(error.response)
              this.editPumpAlertMessage = "Error creating pump."
              this.editPumpAlertVariant = "warning"
              this.showEditPumpAlert = true
            })
        }

        this.pumpIsSaving = false
      },

      closeEditPump() {
        this.showPumpModal = false
        this.editPump = JSON.parse(JSON.stringify(newPumpObj))
        this.editPumpYN = null
        this.editPumpIndex = null
        this.editPumpAlertMessage = ""
        this.editPumpAlertVariant = ""
        this.showEditPumpAlert = false
      },

      async goPumpDetail(item, index) {
        this.isGettingPumpDetail = true
        this.editPumpYN = true
        this.editPumpIndex = this.pumpList.findIndex((x) => x.pump_id === item.pump_id)
        await django_session
          .get("erp/pump/" + item.pump_id + "/")
          .then((response) => {
            this.editPump = response.data
            this.$store.dispatch("pump/setPumpId", item.pump_id)
          })
          .catch((error) => {
            console.log(error.response)
          })
        this.isGettingPumpDetail = false
      },

      addNoteToPump() {
        this.newPumpNoteInput = ""
        this.showAddNoteModal = true
      },

      async saveNewPumpNote() {
        this.pumpNoteIsSaving = true
        await django_session
          .post("erp/pump/note/create/", {
            note: this.newPumpNoteInput,
            pump: this.pumpId,
            created_by: this.user.user_id,
            modified_by: this.user.user_id,
          })
          .then((response) => {
            this.editPump.notes.unshift(response.data)
            this.addNoteAlertMessage = "Note added to pump"
            this.addNoteAlertVariant = "success"
            this.showAddNoteAlert = true
          })
          .catch((error) => {
            this.addNoteAlertMessage = "Error adding note " + error.response.data
            this.addNoteAlertVariant = "warning"
            this.showAddNoteAlert = true
          })
        this.pumpNoteIsSaving = false
      },

      closeNewPumpNote() {
        this.showAddNoteModal = false
        this.addNoteAlertMessage = ""
        this.addNoteAlertVariant = ""
        this.showAddNoteAlert = false
        this.newPumpNoteInput = ""
      },

      openTransferPump(transferType) {
        this.transferType = transferType
        this.showTransferPumpModal = true
      },

      async searchCustomer() {
        this.isSearchingForCustomer = true
        await django_session
          .get("erp/customer/search/", {
            params: {
              keyword: this.searchCustomerToTransfer,
              hide_inactive_yn: true,
              hide_prospects_yn: true,
              page_size: 0,
            },
          })
          .then((response) => {
            // console.log(response.data)
            this.searchCustomerResults = response.data
            this.showCustomerSearchResults = true
          })
          .catch((error) => {
            console.log(error.response)
          })
        this.isSearchingForCustomer = false
      },

      clearSearchCustomer() {
        this.showCustomerSearchResults = false
        this.searchCustomerResults = []
        this.searchCustomerToTransfer = ""
      },

      selectCustomerForTransfer(item) {
        // console.log(item, index)
        this.transferPumpToCustomer = item
        // this.showCustomerSearchResults = false
      },

      confirmTransfer() {
        this.showTransferConfirm = true
        this.showCustomerSearchResults = false
      },

      async saveTransferPump() {
        this.showTransferConfirm = false
        this.pumpTransferSaveComplete = false
        this.pumpTransferIsSaving = true
        if (this.transferType === "depot") {
          await django_session
            .post("erp/pump/transfer/create/", {
              pump: this.editPump.pump_id,
              transfer_date: this.transferDate,
              transfer_type: this.transferType,
              depot: this.transferPumpToDepotId,
              customer: null,
              sage_customer_id: null,
              note: this.transferNotes,
              customer_is_rental_depot_yn: this.customerIsRentalDepotYN,
            })
            .then((response) => {
              console.log(response)
              this.transferPumpAlertVariant = "success"
              this.transferPumpAlertMessage = "Pump transfer complete."
              this.editPump = response.data
            })
            .catch((error) => {
              console.log(error)
              this.transferPumpAlertVariant = "danger"
              this.transferPumpAlertMessage = "Pump transfer error."
            })
        }

        if (this.transferType === "customer") {
          await django_session
            .post("erp/pump/transfer/create/", {
              pump: this.editPump.pump_id,
              transfer_date: this.transferDate,
              transfer_type: this.transferType,
              customer: this.transferPumpToCustomer.erp_customer_id,
              sage_customer_id: this.transferPumpToCustomer.sage_customer_id,
              depot: null,
              note: this.transferNotes,
              customer_is_rental_depot_yn: this.customerIsRentalDepotYN,
            })
            .then((response) => {
              console.log(response)
              this.transferPumpAlertVariant = "success"
              this.transferPumpAlertMessage = "Pump transfer complete."
              this.editPump = response.data
            })
            .catch((error) => {
              console.log(error)
              this.transferPumpAlertVariant = "danger"
              this.transferPumpAlertMessage = "Pump transfer error."
            })
        }
        this.pumpTransferSaveComplete = true
        this.pumpTransferIsSaving = false
        this.showTransferPumpAlert = true
      },

      closeTransferPump() {
        this.showCustomerSearchResults = []
        this.searchCustomerResults = []
        this.showTransferPumpModal = false
        this.transferPumpToCustomer = { lid: null }
        this.transferPumpToDepotId = null
        this.searchCustomerToTransfer = ""
        this.transferType = ""
        this.transferDate = null
        this.transferNotes = ""
        this.transferPumpAlertVariant = ""
        this.transferPumpAlertMessage = ""
        this.showTransferPumpAlert = false
        this.pumpTransferSaveComplete = false
        this.customerIsRentalDepotYN = false
        this.showTransferConfirm = false
        this.$store.dispatch("pump/setPumpId", null)
        this.$store.dispatch("pump/setPumpObj", {})
        this.$store.dispatch("pump/setEditPumpFromDepotFlat", false)
      },

      updatePumpActive() {
        this.showActiveInactivePump = true
        this.pumpActiveInactiveDepot = null
        this.pumpActiveInactiveReasonText = ""
        // this.editPump.is_active_yn = !this.editPump.is_active_yn
        if (this.editPump.active_yn === false) {
          console.log("Make Pump Inactive")
        }
        if (this.editPump.active_yn === true) {
          console.log("Make Pump Active")
        }
      },



      async savePumpActive() {
        // mark pump active flag
        // create movement record
        // create note
        // update pump record
        // add or remove the inactive date
        // if inactive, update inactive reason
        // transfer to inactive depot
        // return updated record
        this.waitingForPumpActiveSave = true
        await django_session
          .patch("erp/pump/" + this.editPump.pump_id + "/", {
            active_yn: this.editPump.active_yn,
            inactive_reason: this.pumpActiveInactiveReasonText,
            move_to_depot: this.pumpActiveInactiveDepot,
            is_leased_yn: this.editPump.is_leased_yn,
          })
          .then((response) => {
            console.log(response.data)
            this.editPump = response.data
            this.pumpList[this.editPumpIndex].active_yn = response.data.active_yn
            this.pumpList[this.editPumpIndex].is_leased_yn = response.data.is_leased_yn
            this.editPumpAlertVariant = "success"
            this.editPumpAlertMessage = "Pump updated."
            this.showEditPumpAlert = true
          })
          .catch((error) => {
            console.log(error.response)
            this.editPumpAlertVariant = "warning"
            this.editPumpAlertMessage = "Error updating pump."
            this.showEditPumpAlert = true
          })
        this.showActiveInactivePump = false
        this.waitingForPumpActiveSave = false
      },

      cancelUpdatePumpActive() {
        this.showActiveInactivePump = false
        this.editPump.active_yn = !this.editPump.active_yn
        this.pumpActiveInactiveDepot = null
        this.pumpActiveInactiveReasonText = ""
      },
    },
  }
</script>
