export const pumpTypeObj = {
  pump_type_id: null,
  name: "",
  name_fr: "",
  item_id: "",
  warranty_years: null,
  selected: true,
  created_at: null,
  created_by: null,
  modified_at: null,
  modified_by: null
}

export const newPumpObj = {
  pump_id: null,
  serial_no: "",
  pump_type: null,
  purchase_date: null,
  is_leased_yn: false,
  is_available_yn: true,
  is_deleted_yn: false,
  active_yn: true,
  inactive_date: null,
  inactive_reason: "",
  depot_id: null,
  customer_id: null
}